// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initialize authentication state
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const response = await axios.get("/api/auth/profile");
        setUser(response.data);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    initializeAuth();
  }, []);

  // Check for Google OAuth redirect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      // Clean URL
      window.history.replaceState({}, document.title, window.location.pathname);
      // Fetch user profile after Google login
      const fetchUserAfterGoogleLogin = async () => {
        try {
          const response = await axios.get("/api/auth/profile");
          setUser(response.data);
        } catch (error) {
          console.error(
            "Failed to fetch user profile after Google login:",
            error
          );
          setUser(null);
        }
      };
      fetchUserAfterGoogleLogin();
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post("/api/auth/login", { email, password });
      // If login successful
      if (response.data.user) {
        setUser(response.data.user);
        return { user: response.data.user };
      }
      return response.data;
    } catch (error) {
      return { error: error.response?.data?.error || "Login failed" };
    }
  };

  const signup = async (userData) => {
    try {
      await axios.post("/api/auth/signup", userData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return true;
    } catch (error) {
      console.error("Signup failed:", error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await axios.post("/api/auth/logout");
      setUser(null);
      return true;
    } catch (error) {
      console.error("Logout error:", error);
      return false;
    }
  };

  const resendVerificationEmail = async (email) => {
    try {
      const response = await axios.post("/api/auth/resend-verification", {
        email,
      });
      return response.data.success;
    } catch (error) {
      console.error("Error resending verification:", error);
      return false;
    }
  };

  // Add Google authentication handler
  const handleGoogleLogin = () => {
    // Redirect to backend Google auth route
    window.location.href = "/api/auth/google";
  };

  // Add forgot password handler
  const forgotPassword = async (email) => {
    try {
      const response = await axios.post("/api/auth/forgot-password", { email });
      return { success: true, message: response.data.message };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to process request",
      };
    }
  };

  // Add reset password handler
  const resetPassword = async (token, password) => {
    try {
      const response = await axios.post("/api/auth/reset-password", {
        token,
        password,
      });
      return { success: true, message: response.data.message };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to reset password",
      };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        signup,
        logout,
        resendVerificationEmail,
        handleGoogleLogin,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
